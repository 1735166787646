
import Head from "next/head";
import styled from "styled-components";
import tw from "twin.macro";
import fs from "fs";

import {
  AnimateIn,
  Container,
  LatestPosts,
  Title
} from "@/components";
import { generateRss, getAllPosts } from "@/util";

export const getStaticProps = async () => {
  const posts = getAllPosts;
  const rss = generateRss(posts);

  fs.writeFileSync("./public/rss.xml", rss);

  return {
    props: {},
  };
};

export const StyledServices = styled.div`
  ${tw`pt-10 pb-16 bg-grey sm:-mt-20 sm:pt-32 lg:pt-40 lg:pb-20 `};

  @media (min-width: 1200px) {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100vw - 120px);
  }
`;

const Home = () => {
  return (
    <>
      <Head>
        <title>Thoughts of a website developer | Luke Brown</title>
        <meta
          name="description"
          content="I write about web design, development and sometimes other things entirely."
        />
        <meta
          property="og:title"
          content="Freelance Website Developer & Designer | Luke Brown"
        />
        <meta
          property="og:description"
          content="Cardiff based website developer, serving clients and agencies of all shapes and sizes to provide online experiences that are a joy to use."
        />
        <meta property="og:image" content="/images/og.png" />
      </Head>

      <AnimateIn>
        <Container>
          <div className="mt-4 md:mt-8">
            <Title>
              I write about web design, development and sometimes other things entirely.
            </Title>
          </div>

          <div className="h-1 bg-white w-8 mt-4 md:w-20 md:mt-6" />

          <div className="mt-12 lg:mt-16">
            <LatestPosts count={5} />
          </div>
        </Container>
      </AnimateIn>
    </>
  );
};

export default Home;
